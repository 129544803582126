<template>
  <div
    v-if="subscription.allow_billets && info.iugu_account_status === 'accepted' && subscription.iugu_activated && info.create_billets"
  >
    <escolher-cliente v-if="!isClientSet" title="Receber por boleto" show-consult-suggestion />
    <payment-wizard v-else-if="isClientSet && hasStatements" />
    <main v-else>
      <escolher-cliente
        :message="`${client.name} não possui faturas a receber.`"
        :client_id="client.id"
        :suggestion="suggestion"
        show-error
        title="Recebimento por boleto"
      />
    </main>
  </div>
  <div class="cannot-access" v-else>
    <div v-if="!subscription.allow_billets">
      <h2
        :class="{notAllowed: !subscription.allow_billets}"
      >Você precisa fazer o upgrade do seu plano para emitir boletos para os seus clientes</h2>
      <br />
      <el-button type="success" @click="$router.push('/assinar-plano')">Ver planos</el-button>
    </div>

    <div v-else-if="info.iugu_account_status != 'accepted'">
      <h2>Para realizar as emissões de boletos é necessário finalizar as configurações da conta.</h2>
      <br />
      <el-button type="success" @click="$router.push('/configuracoes/boletos')">Configurar conta</el-button>
    </div>

    <div v-else-if="!info.create_billets">
      <h2
        :class="{notAllowed: !subscription.allow_billets}"
      >Para realizar as emissões de boletos é necessário finalizar as configurações da conta.</h2>
      <br />
      <el-button type="success" @click="$router.push('/assinar-plano')">Ver planos</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as types from "@/store/types";
import EscolherCliente from "@/containers/EscolherCliente";
import PaymentWizard from "@/containers/Payment/Wizard";

export default {
  components: {
    PaymentWizard,
    EscolherCliente
  },
  data() {
    return {
      suggestion: {
        label: "Vender",
        routeName: "Detalhes da venda"
      }
    };
  },
  computed: {
    ...mapGetters({
      isClientSet: types.CLIENT_IS_SET,
      client: types.CLIENT_DETAILS,
      storeID: types.AUTH_ACTIVE_STORE,
      subscription: types.SUBSCRIPTION_CONFIG,
      info: types.STORE_INFO
    }),
    hasStatements() {
      const statements = this.client.user_statements;

      if (!statements) {
        return false;
      }

      const closed =
        (statements.closed_statement &&
          +statements.closed_statement.total_remaining_amount) ||
        0;
      const open =
        (statements.open_statement &&
          +statements.open_statement.total_remaining_amount) ||
        0;
      const future =
        (statements.future_statement &&
          +statements.future_statement.total_remaining_amount) ||
        0;

      return Math.abs(closed) + Math.abs(open) + Math.abs(future) > 0;
    },
    iuguStatus() {
      const { iugu_account_status } = this.info;
      const statuses = {
        pending: "Pendente",
        accepted: "Aprovado",
        registered: "Registrado",
        rejected: "Recusado",
        not_sent: "Pendente",
        waiting_iugu: "Pendente",
        error: "Erro(Dados incorretos)"
      };
      return statuses[iugu_account_status];
    }
  },
  async mounted() {
    try {
      await this.$store.dispatch(types.STORE_INFO, this.storeID);
    } catch (error) {
      if (this.$raven && error.status === 500) {
        this.$raven.captureException(error.message, { req: error });
      }
      this.$notify.error({ title: error.message });
    }
  }
};
</script>

<style lang="scss" scoped>
.cannot-access {
  text-align: center;
  h2 {
    text-align: center;
    margin-top: 2rem;
    b {
      font-weight: bold;
      color: $laranja;
    }
  }
  .notAllowed {
    font-weight: bold;
    color: $laranja;
  }
}
</style>

<template>
  <section class="payment-cards-wrapper">
    <el-card v-loading="loading" class="payment-details-card">
      <h2>Detalhamento da fatura</h2>
      <el-collapse v-model="activeStatements">
        <el-collapse-item
          v-if="user.user_statements.closed_statement"
          title="FATURA FECHADA"
          name="closed_statements"
        >
          <template slot="title">
            <section class="collapse-header">
              <span>FATURA FECHADA</span>
              <hide-at :breakpoints="{ small: 320, medium: 1170, large: 1600 }" breakpoint="medium">
                <div>
                  <div class="wrapper">
                    <span>Data de vencimento</span>
                    <span class="content">
                      {{
                      formatDate(
                      user.user_statements.closed_statement.due_date
                      )
                      }}
                    </span>
                  </div>
                  <div class="wrapper">
                    <span>Total à pagar</span>
                    <span class="content">
                      {{
                      formatTotal(
                      user.user_statements.closed_statement
                      .total_remaining_amount
                      )
                      }}
                    </span>
                  </div>
                </div>
              </hide-at>
            </section>
          </template>
          <payment-selector
            :data="user.user_statements.closed_statement"
            :deselected-item="deselectedItem"
          />
        </el-collapse-item>
        <el-collapse-item
          v-if="
            user.user_statements.open_statement ||
              user.user_statements.future_statement
          "
          title="PRÓXIMAS FATURAS"
          name="future_statements"
        >
          <section v-if="user.user_statements.open_statement" class="collapse-header">
            <h4 class="statement-title">FATURA EM ABERTO</h4>
            <div>
              <div class="wrapper">
                <span>Data de vencimento</span>
                <span class="content">{{ formatDate(user.user_statements.open_statement.due_date) }}</span>
              </div>
            </div>
          </section>
          <payment-selector
            v-if="user.user_statements.open_statement"
            :data="user.user_statements.open_statement"
            :future="true"
            :deselected-item="deselectedItem"
          />
          <h4 v-if="user.user_statements.future_statement" class="statement-title">DEMAIS FATURAS</h4>
          <payment-future-statements-list
            v-if="user.user_statements.future_statement"
            :statements="user.user_statements.future_statement.next_statements"
            :deselected-item="deselectedItem"
          />
        </el-collapse-item>
      </el-collapse>
    </el-card>
    <el-card v-loading="loading" class="payment-options-card">
      <h2>Total do boleto</h2>
      <div class="amount-wrapper">
        <h3>Valor a receber</h3>
        <money v-model="totalAmountToPay" v-bind="$money" class="amount-value" />
      </div>
      <payment-selector-list
        :total="totalAmountToPay"
        :hide-total="true"
        :reverse="true"
        :action="true"
      />
      <div class="due_date-input-wrapper">
        <div class="label">
          <span>Data de vencimento</span>
          <el-date-picker
            v-model="due_date"
            :default="null"
            type="date"
            format="dd/MM/yyyy"
            :picker-options="pickerOptions"
            placeholder="Escolha um dia"
          />
        </div>
      </div>
      <el-button
        :disabled="totalAmountToPay <= 0 || !due_date"
        class="submit-button"
        type="primary"
        size="small"
        round
        @click="handleSubmit"
      >Gerar boleto</el-button>
    </el-card>
  </section>
</template>

<script>
import { Money } from "v-money";
import { mapGetters } from "vuex";
import moment from "moment";
import { hideAt } from "vue-breakpoints";

import * as types from "@/store/types";
import toMoney from "@/helpers/toMoney";

import PaymentSelector from "@/components/Payment/PaymentSelector";
import PaymentSelectorList from "@/components/Payment/PaymentSelectorList";
import PaymentFutureStatementsList from "@/components/Payment/PaymentFutureStatementsList";

export default {
  components: {
    Money,
    hideAt,
    PaymentSelector,
    PaymentSelectorList,
    PaymentFutureStatementsList
  },
  data() {
    return {
      activeStatements: ["closed_statements"],
      totalAmountToPay: 0,
      due_date: null,
      deselectedItem: null,
      loading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        }
      }
    };
  },
  computed: {
    ...mapGetters({
      user: types.CLIENT_DETAILS,
      paymentItems: types.PAYMENT_ITEMS
    })
  },
  methods: {
    formatTotal(amount) {
      if (amount <= 0) {
        return this.formatMoney(0);
      }
      return this.formatMoney(amount);
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
    formatMoney(amount) {
      return toMoney(amount);
    },
    async handleSubmit() {
      this.loading = true;
      const formatedDate = moment(this.due_date, "DD/MM/YYYY").format(
        "YYYY-MM-DD"
      );
      await this.$store.dispatch(types.PAYMENT_DETAILS, {
        amount: this.totalAmountToPay,
        due_date: formatedDate
      });
      this.loading = false;
      this.$router.push({ name: "Sumario do recebimento por boleto" });
    },
  }
};
</script>

<style lang="scss">
.collapse-header {
  display: flex;
  justify-content: space-between;
  padding-right: 14px;
  .wrapper {
    display: inline-block;
    height: 48px;
    margin: 6px 0 0 12px;
    span {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      text-align: center;
      font-size: 14px;
      display: block;
      line-height: 14px;
      font-weight: 300;
    }
    .content {
      font-family: Montserrat;
      letter-spacing: 1.025px;
      color: $laranja;
      font-size: 18px;
      line-height: 22px;
      font-weight: 700;
    }
  }
}
.payment-cards-wrapper {
  position: relative;
  margin: 0 1rem 1rem 1rem;
  h2 {
    margin-bottom: 1rem;
  }
  .selector-button-amount {
    padding: 0px 2px;
    font-size: 11px;
    height: 16px;
    position: absolute;
    top: 0px;
    width: 59px;
    right: 0px;
  }
  .el-collapse-item {
    .el-collapse-item__header {
      font-size: 14px;
      font-family: Montserrat;
      color: $preto;
      font-weight: 700;
      letter-spacing: 1.025px;
      padding: 0 4px;
    }
  }
  @media screen and (max-width: 425px) {
    grid-template-columns: 100%;
  }
}
.statement-title {
  font-size: 14px;
  font-family: Montserrat;
  color: $preto;
  font-weight: 700;
  letter-spacing: 1.025px;
  margin: 12px 0;
  padding: 0 4px;
}
.payment-details-card {
  vertical-align: text-top;
  width: calc(50% - 7px);
  display: inline-block;
  @media screen and (max-width: 700px) {
    width: 100%;
    display: block;
  }
}
.payment-options-card {
  vertical-align: text-top;
  margin-left: 14px;
  width: calc(50% - 7px);
  display: inline-block;
  @media screen and (max-width: 700px) {
    width: 100%;
    display: block;
    margin: 14px 0 0 0;
  }
  .amount-wrapper {
    text-align: center;
    h3 {
      margin: 0;
    }
  }
  .due_date-input-wrapper {
    margin-top: 1rem;
    .label {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        font-family: Montserrat;
        color: $preto;
        letter-spacing: 1.025px;
      }
    }
  }
  .amount-value {
    margin: 4px 0 1rem 0;
    background: transparent;
    border: none;
    width: 100%;
    text-align: center;
    font-size: 38px !important;
    font-family: Montserrat;
    font-weight: 700;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 0;
    color: $laranja;
    outline: none;
    padding-bottom: 4px;
  }
  .el-card__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  .submit-button {
    margin-top: 1rem;
    align-self: flex-end;
    width: 100%;
  }
}
</style>
